import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _04057be1 = () => interopDefault(import('../pages/comments/index.vue' /* webpackChunkName: "pages/comments/index" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _2fb5509a = () => interopDefault(import('../pages/cookies-policy.vue' /* webpackChunkName: "pages/cookies-policy" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _4e147af6 = () => interopDefault(import('../pages/escorts-news/index.vue' /* webpackChunkName: "pages/escorts-news/index" */))
const _6ac1bd6c = () => interopDefault(import('../pages/escorts-photo-gallery/index.vue' /* webpackChunkName: "pages/escorts-photo-gallery/index" */))
const _680e099d = () => interopDefault(import('../pages/escorts-updates/index.vue' /* webpackChunkName: "pages/escorts-updates/index" */))
const _279e373b = () => interopDefault(import('../pages/escorts-videos/index.vue' /* webpackChunkName: "pages/escorts-videos/index" */))
const _38e84e52 = () => interopDefault(import('../pages/go.vue' /* webpackChunkName: "pages/go" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _3e7e4938 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _4b01347c = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _3fdcfe1b = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _43fe2188 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _c4b4b522 = () => interopDefault(import('../pages/auth/signin.vue' /* webpackChunkName: "pages/auth/signin" */))
const _9b880836 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _430edd51 = () => interopDefault(import('../pages/auth/signup-extended.vue' /* webpackChunkName: "pages/auth/signup-extended" */))
const _26e58646 = () => interopDefault(import('../pages/auth/signup-proceed.vue' /* webpackChunkName: "pages/auth/signup-proceed" */))
const _3527f189 = () => interopDefault(import('../pages/dashboard/billing/index.vue' /* webpackChunkName: "pages/dashboard/billing/index" */))
const _5b46f4eb = () => interopDefault(import('../pages/dashboard/blacklist/index.vue' /* webpackChunkName: "pages/dashboard/blacklist/index" */))
const _3c9014bc = () => interopDefault(import('../pages/dashboard/comments/index.vue' /* webpackChunkName: "pages/dashboard/comments/index" */))
const _57479079 = () => interopDefault(import('../pages/dashboard/my-favourites/index.vue' /* webpackChunkName: "pages/dashboard/my-favourites/index" */))
const _712a970e = () => interopDefault(import('../pages/dashboard/my-profiles/index.vue' /* webpackChunkName: "pages/dashboard/my-profiles/index" */))
const _49a1d55b = () => interopDefault(import('../pages/dashboard/news/index.vue' /* webpackChunkName: "pages/dashboard/news/index" */))
const _f0f0a514 = () => interopDefault(import('../pages/dashboard/notifications/index.vue' /* webpackChunkName: "pages/dashboard/notifications/index" */))
const _f8e88988 = () => interopDefault(import('../pages/dashboard/pin-board/index.vue' /* webpackChunkName: "pages/dashboard/pin-board/index" */))
const _5ce102b7 = () => interopDefault(import('../pages/dashboard/pin-board-users/index.vue' /* webpackChunkName: "pages/dashboard/pin-board-users/index" */))
const _a4aeecc4 = () => interopDefault(import('../pages/dashboard/private-messages/index.vue' /* webpackChunkName: "pages/dashboard/private-messages/index" */))
const _1c623b29 = () => interopDefault(import('../pages/dashboard/reviews/index.vue' /* webpackChunkName: "pages/dashboard/reviews/index" */))
const _227a442b = () => interopDefault(import('../pages/dashboard/settings/index.vue' /* webpackChunkName: "pages/dashboard/settings/index" */))
const _57a0b5dd = () => interopDefault(import('../pages/dashboard/support/index.vue' /* webpackChunkName: "pages/dashboard/support/index" */))
const _f0578e84 = () => interopDefault(import('../pages/dashboard/manager/balances.vue' /* webpackChunkName: "pages/dashboard/manager/balances" */))
const _46269370 = () => interopDefault(import('../pages/dashboard/manager/banners.vue' /* webpackChunkName: "pages/dashboard/manager/banners" */))
const _10d1fb13 = () => interopDefault(import('../pages/dashboard/manager/black-list.vue' /* webpackChunkName: "pages/dashboard/manager/black-list" */))
const _6c923751 = () => interopDefault(import('../pages/dashboard/manager/favourites.vue' /* webpackChunkName: "pages/dashboard/manager/favourites" */))
const _4ff6ab1a = () => interopDefault(import('../pages/dashboard/manager/messages.vue' /* webpackChunkName: "pages/dashboard/manager/messages" */))
const _19dfb7fa = () => interopDefault(import('../pages/dashboard/manager/news.vue' /* webpackChunkName: "pages/dashboard/manager/news" */))
const _9e513a54 = () => interopDefault(import('../pages/dashboard/manager/photo-check.vue' /* webpackChunkName: "pages/dashboard/manager/photo-check" */))
const _0a0e8f22 = () => interopDefault(import('../pages/dashboard/manager/pornstar-check.vue' /* webpackChunkName: "pages/dashboard/manager/pornstar-check" */))
const _6d9fd611 = () => interopDefault(import('../pages/dashboard/manager/profiles.vue' /* webpackChunkName: "pages/dashboard/manager/profiles" */))
const _28087c8d = () => interopDefault(import('../pages/dashboard/manager/reported-profiles.vue' /* webpackChunkName: "pages/dashboard/manager/reported-profiles" */))
const _08dd56c4 = () => interopDefault(import('../pages/dashboard/manager/reviews.vue' /* webpackChunkName: "pages/dashboard/manager/reviews" */))
const _437b21ca = () => interopDefault(import('../pages/dashboard/manager/settings.vue' /* webpackChunkName: "pages/dashboard/manager/settings" */))
const _486ae878 = () => interopDefault(import('../pages/dashboard/manager/support.vue' /* webpackChunkName: "pages/dashboard/manager/support" */))
const _28531478 = () => interopDefault(import('../pages/dashboard/manager/tariffs.vue' /* webpackChunkName: "pages/dashboard/manager/tariffs" */))
const _280d6271 = () => interopDefault(import('../pages/dashboard/manager/users.vue' /* webpackChunkName: "pages/dashboard/manager/users" */))
const _790e8cd4 = () => interopDefault(import('../pages/dashboard/member/comments.vue' /* webpackChunkName: "pages/dashboard/member/comments" */))
const _13364b7b = () => interopDefault(import('../pages/dashboard/member/my-favourites.vue' /* webpackChunkName: "pages/dashboard/member/my-favourites" */))
const _edc9ab90 = () => interopDefault(import('../pages/dashboard/member/notifications.vue' /* webpackChunkName: "pages/dashboard/member/notifications" */))
const _5dccc836 = () => interopDefault(import('../pages/dashboard/member/private-messages.vue' /* webpackChunkName: "pages/dashboard/member/private-messages" */))
const _546121eb = () => interopDefault(import('../pages/dashboard/member/reviews.vue' /* webpackChunkName: "pages/dashboard/member/reviews" */))
const _6870bb83 = () => interopDefault(import('../pages/dashboard/member/settings.vue' /* webpackChunkName: "pages/dashboard/member/settings" */))
const _d82298c2 = () => interopDefault(import('../pages/dashboard/member/support.vue' /* webpackChunkName: "pages/dashboard/member/support" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _cc1d2c54 = () => interopDefault(import('../pages/dashboard/my-profiles/_profile/index.vue' /* webpackChunkName: "pages/dashboard/my-profiles/_profile/index" */))
const _bd536d20 = () => interopDefault(import('../pages/dashboard/my-profiles/_profile/photos/index.vue' /* webpackChunkName: "pages/dashboard/my-profiles/_profile/photos/index" */))
const _09fc0f16 = () => interopDefault(import('../pages/dashboard/my-profiles/_profile/service-info/index.vue' /* webpackChunkName: "pages/dashboard/my-profiles/_profile/service-info/index" */))
const _c2e734dc = () => interopDefault(import('../pages/dashboard/my-profiles/_profile/settings/index.vue' /* webpackChunkName: "pages/dashboard/my-profiles/_profile/settings/index" */))
const _d2a834dc = () => interopDefault(import('../pages/dashboard/my-profiles/_profile/statistics/index.vue' /* webpackChunkName: "pages/dashboard/my-profiles/_profile/statistics/index" */))
const _4685e442 = () => interopDefault(import('../pages/dashboard/my-profiles/_profile/video/index.vue' /* webpackChunkName: "pages/dashboard/my-profiles/_profile/video/index" */))
const _2b50e247 = () => interopDefault(import('../pages/auth/email/_check/_hash.vue' /* webpackChunkName: "pages/auth/email/_check/_hash" */))
const _4cd869d7 = () => interopDefault(import('../pages/categories/_gender.vue' /* webpackChunkName: "pages/categories/_gender" */))
const _66576855 = () => interopDefault(import('../pages/escorts-updates/_slug.vue' /* webpackChunkName: "pages/escorts-updates/_slug" */))
const _4f2493ac = () => interopDefault(import('../pages/profile/_slug.vue' /* webpackChunkName: "pages/profile/_slug" */))
const _500944da = () => interopDefault(import('../pages_extended/static.vue' /* webpackChunkName: "pages_extended/static.vue" */))
const _42ee0ddd = () => interopDefault(import('../pages_extended/catalog.vue' /* webpackChunkName: "pages_extended/catalog.vue" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/comments",
    component: _04057be1,
    name: "comments"
  }, {
    path: "/contact-us",
    component: _521bf555,
    name: "contact-us"
  }, {
    path: "/cookies-policy",
    component: _2fb5509a,
    name: "cookies-policy"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/escorts-news",
    component: _4e147af6,
    name: "escorts-news"
  }, {
    path: "/escorts-photo-gallery",
    component: _6ac1bd6c,
    name: "escorts-photo-gallery"
  }, {
    path: "/escorts-updates",
    component: _680e099d,
    name: "escorts-updates"
  }, {
    path: "/escorts-videos",
    component: _279e373b,
    name: "escorts-videos"
  }, {
    path: "/go",
    component: _38e84e52,
    name: "go"
  }, {
    path: "/maintenance",
    component: _4e16ad99,
    name: "maintenance"
  }, {
    path: "/reviews",
    component: _3e7e4938,
    name: "reviews"
  }, {
    path: "/test",
    component: _4b01347c,
    name: "test"
  }, {
    path: "/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password"
  }, {
    path: "/auth/forgot-password",
    component: _43fe2188,
    name: "auth-forgot-password"
  }, {
    path: "/auth/signin",
    component: _c4b4b522,
    name: "auth-signin"
  }, {
    path: "/auth/signup",
    component: _9b880836,
    name: "auth-signup"
  }, {
    path: "/auth/signup-extended",
    component: _430edd51,
    name: "auth-signup-extended"
  }, {
    path: "/auth/signup-proceed",
    component: _26e58646,
    name: "auth-signup-proceed"
  }, {
    path: "/dashboard/billing",
    component: _3527f189,
    name: "dashboard-billing"
  }, {
    path: "/dashboard/blacklist",
    component: _5b46f4eb,
    name: "dashboard-blacklist"
  }, {
    path: "/dashboard/comments",
    component: _3c9014bc,
    name: "dashboard-comments"
  }, {
    path: "/dashboard/my-favourites",
    component: _57479079,
    name: "dashboard-my-favourites"
  }, {
    path: "/dashboard/my-profiles",
    component: _712a970e,
    name: "dashboard-my-profiles"
  }, {
    path: "/dashboard/news",
    component: _49a1d55b,
    name: "dashboard-news"
  }, {
    path: "/dashboard/notifications",
    component: _f0f0a514,
    name: "dashboard-notifications"
  }, {
    path: "/dashboard/pin-board",
    component: _f8e88988,
    name: "dashboard-pin-board"
  }, {
    path: "/dashboard/pin-board-users",
    component: _5ce102b7,
    name: "dashboard-pin-board-users"
  }, {
    path: "/dashboard/private-messages",
    component: _a4aeecc4,
    name: "dashboard-private-messages"
  }, {
    path: "/dashboard/reviews",
    component: _1c623b29,
    name: "dashboard-reviews"
  }, {
    path: "/dashboard/settings",
    component: _227a442b,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/support",
    component: _57a0b5dd,
    name: "dashboard-support"
  }, {
    path: "/dashboard/manager/balances",
    component: _f0578e84,
    name: "dashboard-manager-balances"
  }, {
    path: "/dashboard/manager/banners",
    component: _46269370,
    name: "dashboard-manager-banners"
  }, {
    path: "/dashboard/manager/black-list",
    component: _10d1fb13,
    name: "dashboard-manager-black-list"
  }, {
    path: "/dashboard/manager/favourites",
    component: _6c923751,
    name: "dashboard-manager-favourites"
  }, {
    path: "/dashboard/manager/messages",
    component: _4ff6ab1a,
    name: "dashboard-manager-messages"
  }, {
    path: "/dashboard/manager/news",
    component: _19dfb7fa,
    name: "dashboard-manager-news"
  }, {
    path: "/dashboard/manager/photo-check",
    component: _9e513a54,
    name: "dashboard-manager-photo-check"
  }, {
    path: "/dashboard/manager/pornstar-check",
    component: _0a0e8f22,
    name: "dashboard-manager-pornstar-check"
  }, {
    path: "/dashboard/manager/profiles",
    component: _6d9fd611,
    name: "dashboard-manager-profiles"
  }, {
    path: "/dashboard/manager/reported-profiles",
    component: _28087c8d,
    name: "dashboard-manager-reported-profiles"
  }, {
    path: "/dashboard/manager/reviews",
    component: _08dd56c4,
    name: "dashboard-manager-reviews"
  }, {
    path: "/dashboard/manager/settings",
    component: _437b21ca,
    name: "dashboard-manager-settings"
  }, {
    path: "/dashboard/manager/support",
    component: _486ae878,
    name: "dashboard-manager-support"
  }, {
    path: "/dashboard/manager/tariffs",
    component: _28531478,
    name: "dashboard-manager-tariffs"
  }, {
    path: "/dashboard/manager/users",
    component: _280d6271,
    name: "dashboard-manager-users"
  }, {
    path: "/dashboard/member/comments",
    component: _790e8cd4,
    name: "dashboard-member-comments"
  }, {
    path: "/dashboard/member/my-favourites",
    component: _13364b7b,
    name: "dashboard-member-my-favourites"
  }, {
    path: "/dashboard/member/notifications",
    component: _edc9ab90,
    name: "dashboard-member-notifications"
  }, {
    path: "/dashboard/member/private-messages",
    component: _5dccc836,
    name: "dashboard-member-private-messages"
  }, {
    path: "/dashboard/member/reviews",
    component: _546121eb,
    name: "dashboard-member-reviews"
  }, {
    path: "/dashboard/member/settings",
    component: _6870bb83,
    name: "dashboard-member-settings"
  }, {
    path: "/dashboard/member/support",
    component: _d82298c2,
    name: "dashboard-member-support"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/dashboard/my-profiles/:profile",
    component: _cc1d2c54,
    alias: "/dashboard/profiles/:profile",
    name: "dashboard-my-profiles-profile"
  }, {
    path: "/dashboard/my-profiles/:profile?/photos",
    component: _bd536d20,
    alias: "/dashboard/profiles/:profile?/photos",
    name: "dashboard-my-profiles-profile-photos"
  }, {
    path: "/dashboard/my-profiles/:profile?/service-info",
    component: _09fc0f16,
    alias: "/dashboard/profiles/:profile?/service-info",
    name: "dashboard-my-profiles-profile-service-info"
  }, {
    path: "/dashboard/my-profiles/:profile?/settings",
    component: _c2e734dc,
    alias: "/dashboard/profiles/:profile?/settings",
    name: "dashboard-my-profiles-profile-settings"
  }, {
    path: "/dashboard/my-profiles/:profile?/statistics",
    component: _d2a834dc,
    alias: "/dashboard/profiles/:profile?/statistics",
    name: "dashboard-my-profiles-profile-statistics"
  }, {
    path: "/dashboard/my-profiles/:profile?/video",
    component: _4685e442,
    alias: "/dashboard/profiles/:profile?/video",
    name: "dashboard-my-profiles-profile-video"
  }, {
    path: "/auth/email/:check?/:hash?",
    component: _2b50e247,
    name: "auth-email-check-hash"
  }, {
    path: "/categories/:gender?",
    component: _4cd869d7,
    name: "categories-gender"
  }, {
    path: "/escorts-updates/:slug?",
    component: _66576855,
    name: "escorts-updates-slug"
  }, {
    path: "/profile/:slug?",
    component: _4f2493ac,
    name: "profile-slug"
  }, {
    path: "/privacy-policy",
    component: _500944da,
    name: "privacy"
  }, {
    path: "/terms-and-conditions",
    component: _500944da,
    name: "terms-and-conditions"
  }, {
    path: "/advertise",
    component: _500944da,
    name: "advertise-with-emiretes-escort"
  }, {
    path: "/about-us",
    component: _500944da,
    name: "about-us"
  }, {
    path: "/:catalog_first_level",
    component: _42ee0ddd,
    name: "catalog-first-level",
    children: [{
      path: "/:catalog_first_level/:catalog_second_level",
      component: _42ee0ddd,
      name: "catalog-second-level",
      children: [{
        path: "/:catalog_first_level/:catalog_second_level/:catalog_third_level",
        component: _42ee0ddd,
        name: "catalog-third-level"
      }]
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
