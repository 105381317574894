
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      footerNav: "menus/getFooterNav",
      headerNav: "v2/nav/getHeaderNav",
      seoTexts: "global/getSeoTexts",
      seoSettings: "global/getSeoSettings",
      defaultFilters: "profiles/getDefaultFilters",
    }),
    isCatalog() {
      return this.$route.name.includes("catalog");
    },
  },
  methods: {
    processNavLink(link) {
      if (
        this.isCatalog &&
        this.defaultFilters.gender &&
        link.includes("categories")
      ) {
        return `${link}/${this.defaultFilters.gender}`;
      }
      return link;
    },
  },
};
